.logoutContainer {
   /* background-image: url('../../assets/Photos/birme/aromatic-bread-bunch-349608.jpg');*/
    background-size: cover;
    height: 100%;
    opacity: 0.9;
}

.logoutLogo{
    width: 200px;
}

.logoutH3 {
    font-size: 50px;
    color: white;
}

.logoutH4 {
    font-size: 30px;
}