.signUpContainer {
   /* background-image: url('../../assets/Photos/apple-color-delicious-1161467.jpg');*/
    background-size: cover;
    height: 100%;
    position: relative;
    overflow: hidden;
    /*transform: scaleX(-1)*/
}


.textCenter {
    text-align: center;
}

.whiteText {
    color: white;
}

.boldText {
    font-weight: bold;
    font-size: 1rem;
}

.landingLink {
    color: darkorange;
    font-weight: bold;
}

#signupinputemail {
    color: white;
}

.signUpLogo {
    position: relative;
    z-index: 100;
    margin-top: 200px;
    margin-left: 50px;
}

.brown {
    background-color: #654321;
    color: white;
}