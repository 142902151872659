.loginContainer {
   /* background-image: url('../../assets/Photos/birme/basil-delicious-food-459469.jpg');*/
    background-size: cover;
    height: 100%;
    opacity: 1;
}

.navy {
    background-color: navy;
    color: white;
}

.landingLinkLogin {
    color: white;
    font-weight: bold;
}

.loginLogo{
    margin-top: 200px;
}